import React from "react";
import PropTypes from "prop-types";

import "./checkoutSummary.css";

const CheckoutSummaryComponent = ({
  editViolations,
  violations,
  violationsTotal,
  serviceFee,
  creditCardFee,
  creditCardFeePercentage,
  text,
  total,
  totalAdditionalTicketFee
}) => (
  <div className="checkout-summary-container">
    <h4>Summary</h4>
    {text && <p className="checkout-summary-secured">{text}</p>}
    <div className="checkout-summary-list">
      {violations.map(violation => (
        <div
          key={violation.violationNumber}
          className="checkout-summary-list-item checkout-summary-violations-item"
        >
          <div># {violation.violationNumber}</div>
          <div className="checkout-summary-item-amount">
            ${violation.amount.toFixed(2)}
          </div>
        </div>
      ))}
      {typeof editViolations === "function" && (
        <div className="checkout-summary-list-action" onClick={editViolations}>
          Add or Remove Violations
        </div>
      )}
    </div>
    <div className="checkout-summary-totals-list">
      <div className="checkout-summary-list-item">
        <div>Tickets</div>
        <div className="checkout-summary-item-amount">
          ${violationsTotal.toFixed(2)}
        </div>
      </div>
      <div className="checkout-summary-list-item">
        <div>Service Fee</div>
        <div className="checkout-summary-item-amount">
          {serviceFee === 0 ? <b>waived</b> : `$${(serviceFee + totalAdditionalTicketFee).toFixed(2)}`}
        </div>
      </div>
      <div className="checkout-summary-list-item">
        <div>
          Processing Fee
        </div>
        <div className="checkout-summary-item-amount">
          {creditCardFee === 0 ? <b>waived</b> : `$${creditCardFee.toFixed(2)}`}
        </div>
      </div>
    </div>
    <div className="checkout-summary-list checkout-summary-grand-total">
      <div className="checkout-summary-list-item">
        <div>Total</div>
        <div className="checkout-summary-item-amount">${total.toFixed(2)}</div>
      </div>
    </div>
  </div>
);

CheckoutSummaryComponent.defaultProps = {
  text: "",
  editViolations: false
};

CheckoutSummaryComponent.propTypes = {
  text: PropTypes.string,
  violations: PropTypes.array.isRequired,
  violationsTotal: PropTypes.number.isRequired,
  creditCardFee: PropTypes.number.isRequired,
  creditCardFeePercentage: PropTypes.number.isRequired,
  serviceFee: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  editViolations: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

export default CheckoutSummaryComponent;
